import { Vue, Component } from 'vue-property-decorator';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox
} from 'bootstrap-vue';
import { togglePasswordVisibility } from '@/@core/mixins/ui/forms';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import ApiClientFactory from '@/api/apiClientFactory';
import { LoginInput } from '@/api/api';
import AuthStorageService from '@/utility/account/auth.storage.service';
import { DEFAULT_ROUTE } from '@/router';
import { mapMutations, mapState } from 'vuex';
import {
  AUTH_STATE_NAMESPACE,
  AuthMutation,
  AuthState
} from '@/store/auth/auth.module-types';

@Component({
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    CustomLabel
  },
  computed: {
    ...mapState(AUTH_STATE_NAMESPACE, [AuthState.Auth])
  },
  methods: {
    ...mapMutations(AUTH_STATE_NAMESPACE, [AuthMutation.setAuth])
  },
  mixins: [togglePasswordVisibility]
})
export default class Login extends Vue {
  setAuth!: (data) => void;

  input = this.getDefaultValueInput();

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
  };

  getDefaultValueInput() {
    return {
      userName: '',
      password: ''
    };
  }

  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        const client = new ApiClientFactory().authClient();
        client
          .login(
            new LoginInput({
              userName: this.input.userName,
              password: this.input.password
            })
          )
          .then((result) => {
            AuthStorageService.setAuthInfo(result);
            this.setAuth(result);

            this.$router.push({ name: DEFAULT_ROUTE });
          });
      }
    });
  }

  //   passwordToggleIcon() {
  //     return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
  //   }
}
